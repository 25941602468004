import type { ComponentType } from "react"
import { useEffect, useState } from "react"

interface WithHrefProp {
    href?: string
}

export function viewMoreDetails<P extends WithHrefProp>(
    Component: ComponentType<P>
): ComponentType<P> {
    return (props) => {
        const [newHref, setNewHref] = useState(props.href || "")

        useEffect(() => {
            const urlParams = new URLSearchParams(window.location.search)
            const utmParams = [
                "utm_source",
                "utm_medium",
                "utm_campaign",
                "utm_content",
                "utm_term",
            ]

            const parameters: string[] = utmParams
                .map((param) => {
                    const value = urlParams.get(param)
                    return value ? `${param}=${value}` : null
                })
                .filter(Boolean) as string[]

            const utmParameters =
                parameters.length > 0 ? `?${parameters.join("&")}` : ""
            const updatedHref = `${window.location.origin}/teams/frontend${utmParameters}`

            setNewHref(updatedHref)
        }, [])

        const handleRedirect = () => (window.location.href = newHref)

        return (
            <div onClick={handleRedirect}>
                <Component {...props} href={newHref} />
            </div>
        )
    }
}
